import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["leftPanel", "rightPanel"];

  connect() {
    document.addEventListener("turbo:before-render", this.updateCachedWidths);
  }

  disconnect() {
    document.removeEventListener(
      "turbo:before-render",
      this.updateCachedWidths,
    );
  }

  updateCachedWidths = (event) => {
    const savedPercent = localStorage.getItem("journal-tasks-split");
    if (!savedPercent) return;

    const newElement = event.detail.newBody;
    const leftPanel = newElement.querySelector("#journal-view");
    const rightPanel = newElement.querySelector("#tasks-view");

    if (leftPanel && rightPanel) {
      const leftPercent = Number(savedPercent);
      const rightPercent = 100 - leftPercent;

      console.log("Cache updating widths:", {
        left: leftPercent,
        right: rightPercent,
      });

      const leftStr = leftPercent.toFixed(8);
      const rightStr = rightPercent.toFixed(8);

      leftPanel.style.width = `${leftStr}%`;
      rightPanel.style.width = `${rightStr}%`;
    }
  };
}
