import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["handle", "leftPanel", "rightPanel"];
  static values = {
    minLeftPercent: { type: Number, default: 60 },
    maxLeftPercent: { type: Number, default: 68 },
  };

  connect() {
    this.dragging = false;
    this.initialX = 0;
    this.initialLeftWidth = 0;
    this.boundDrag = this.drag.bind(this);
    this.boundStopDragging = this.stopDragging.bind(this);

    // Only restore from localStorage if no server-side value was set
    if (!this.leftPanelTarget.style.width) {
      this.restorePosition();
    }
  }

  setCookie(value) {
    // Set cookie with path=/ and max-age of 1 year, and store the exact string value
    const valueStr = value.toFixed(8);
    document.cookie = `journal_tasks_split=${valueStr};path=/;max-age=31536000;SameSite=Lax`;
  }

  restorePosition() {
    const savedPercent = localStorage.getItem("journal-tasks-split");
    if (savedPercent) {
      const leftPercent = Number(savedPercent);

      if (this.isValidSplit(leftPercent)) {
        this.setWidths(leftPercent);
        this.setCookie(leftPercent);
      }
    }
  }

  setWidths(leftPercent) {
    // Store exact values to avoid cumulative rounding errors
    const rightPercent = 100 - leftPercent;
    const leftStr = leftPercent.toFixed(8);
    const rightStr = rightPercent.toFixed(8);

    this.leftPanelTarget.style.width = `${leftStr}%`;
    this.rightPanelTarget.style.width = `${rightStr}%`;
  }

  getContainerWidthWithoutHandle() {
    const containerWidth = this.element.getBoundingClientRect().width;
    const handleWidth = this.handleTarget.getBoundingClientRect().width;
    return containerWidth - handleWidth;
  }

  startDragging(event) {
    event.preventDefault();
    this.dragging = true;
    this.initialX = event.clientX;

    // Get the current computed widths before starting the drag
    const leftWidth = this.leftPanelTarget.getBoundingClientRect().width;
    const containerWidth = this.getContainerWidthWithoutHandle();

    // Convert pixel width to percentage with full precision
    this.initialLeftWidth = (leftWidth / containerWidth) * 100;

    document.body.style.cursor = "col-resize";
    document.addEventListener("mousemove", this.boundDrag);
    document.addEventListener("mouseup", this.boundStopDragging);
  }

  drag(event) {
    if (!this.dragging) return;

    const containerWidth = this.getContainerWidthWithoutHandle();
    const deltaX = event.clientX - this.initialX;
    const newLeftPercent =
      this.initialLeftWidth + (deltaX / containerWidth) * 100;

    if (this.isValidSplit(newLeftPercent)) {
      this.setWidths(newLeftPercent);
    }
  }

  isValidSplit(leftPercent) {
    return (
      leftPercent >= this.minLeftPercentValue &&
      leftPercent <= this.maxLeftPercentValue
    );
  }

  stopDragging() {
    if (this.dragging) {
      const leftWidth = this.leftPanelTarget.getBoundingClientRect().width;
      const containerWidth = this.getContainerWidthWithoutHandle();
      const leftPercent = (leftWidth / containerWidth) * 100;

      const valueStr = leftPercent.toFixed(8);
      localStorage.setItem("journal-tasks-split", valueStr);
      this.setCookie(leftPercent);
    }

    this.dragging = false;
    document.body.style.cursor = "";
    document.removeEventListener("mousemove", this.boundDrag);
    document.removeEventListener("mouseup", this.boundStopDragging);
  }

  disconnect() {
    document.removeEventListener("mousemove", this.boundDrag);
    document.removeEventListener("mouseup", this.boundStopDragging);
  }
}
