import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["image"];
  static values = {
    defaultImage: String,
  };

  connect() {
    console.log("Feature image controller connected");
    // Store the default image path
    this.defaultImageValue = this.imageTarget.src;
  }

  // Called when an accordion item is clicked
  switchImage(event) {
    const imagePath = event.currentTarget.dataset.featureImage;
    if (imagePath) {
      this.imageTarget.src = imagePath;
    } else {
      this.imageTarget.src = this.defaultImageValue;
    }
  }
}
